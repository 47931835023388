<template>
  <v-container
    id="shipments-edit"
    fluid
  >
    <v-skeleton-loader
      v-if="!mounted"
      elevation="2"
      :loading="!mounted"
      type="card-heading, article@2"
    />
    <v-card
      v-if="mounted"
      color="transparent"
      flat
    >
      <v-overlay
        :value="loading"
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card-title>
        <v-icon
          class="mr-2"
        >
          mdi-package-variant
        </v-icon>
        {{ $t('views.shipment.enum.type.' + shipment.type) }}
        <v-icon
          v-if="shipment.warehouse?.internal"
          class="mx-2"
          color="orange"
        >
          mdi-home-account
        </v-icon>
        :
        <span
          v-if="shipment.tracking_number"
          class="font-weight-light mx-2"
        >
          {{ shipment.tracking_number }}
        </span>
        <StatusChip
          v-if="shipment.status"
          :status="shipment.status"
        />
        <v-spacer />
        <v-menu
          bottom
          left
          offset-y
          min-width="300"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :disabled="!capacities.refreshStatus"
              class="py-0"
              @click="refresh"
            >
              <v-list-item-icon
                class="mr-2"
              >
                <v-icon
                  small
                >
                  mdi-timer-refresh-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>
                Actualiser
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item
              :disabled="!capacities.updateDelivery"
              class="py-0"
              @click="modal.deliver.opened = true"
            >
              <v-list-item-icon
                class="mr-2"
              >
                <v-icon
                  small
                >
                  mdi-package-variant-closed-check
                </v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>
                Indiquer la date de livraison
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-row
          v-if="shipment.last_error"
          dense
        >
          <v-col>
            <v-alert
              border="left"
              class="mb-0"
              dense
              icon="mdi-alert-box"
              prominent
              text
              type="error"
            >
              {{ shipment.last_error.created_at | date }} - {{ shipment.last_error.message }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row
          v-if="shipment.deleted_at"
          dense
        >
          <v-col>
            <v-alert
              border="left"
              class="mb-0"
              dense
              icon="mdi-trash-can"
              prominent
              text
              type="error"
            >
              {{ shipment.deleted_at | date }}: Supprimé
            </v-alert>
          </v-col>
        </v-row>
        <v-row
          v-if="shipment.lost"
          dense
        >
          <v-col>
            <v-alert
              border="left"
              class="mb-0"
              dense
              icon="mdi-truck-alert"
              prominent
              text
              type="error"
            >
              {{ shipment.lost_on | date }}: Colis déclaré perdu
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <Address
              title="Expéditeur"
              type="sender"
              :shipment-id="shipment.id"
              :address="shipment.sender_address"
              :email="shipment.sender_email"
              :phone="shipment.sender_phone"
              :disabled="!capacities.updateSender"
              @shipmentUpdated="onShipmentUpdated"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <Address
              title="Destinataire"
              type="recipient"
              :shipment-id="shipment.id"
              :address="shipment.recipient_address"
              :email="shipment.recipient_email"
              :phone="shipment.recipient_phone"
              :disabled="!capacities.updateRecipient"
              @shipmentUpdated="onShipmentUpdated"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-card>
              <v-card-title>
                Détail
                <v-spacer />
                <v-btn
                  text
                  small
                  color="blue lighten-1"
                  @click="modal.provider.opened = true"
                >
                  Modifier
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                    sm="12"
                  >
                    <v-simple-table
                      class="lh_shipment__details"
                    >
                      <tbody>
                        <tr>
                          <td>
                            Logisticien
                          </td>
                          <td>
                            <ProviderChip :provider="shipment.provider" />
                            <v-btn
                              v-if="shipment.provider_reference"
                              :href="getProviderLink(shipment)"
                              color="blue lighten-1"
                              class="mx-2"
                              icon
                              target="_blank"
                            >
                              <v-icon small>
                                mdi-open-in-new
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Transporteur
                          </td>
                          <td>
                            <CarrierChip :carrier="shipment.carrier" />
                            <v-icon
                              v-if="isCarrierTracked(shipment.carrier) === false"
                              class="ml-4"
                              color="orange darken-1"
                              small
                              title="Ce transporteur ne bénéficie pas de la mise à jour de statut"
                            >
                              mdi-sync-off
                            </v-icon>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            N° de suivi
                          </td>
                          <td>
                            <TrackingNumberLink
                              :tracking-number="shipment.tracking_number"
                              :tracking-url="shipment.tracking_url"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Référence
                          </td>
                          <td>{{ shipment.reference }}</td>
                        </tr>
                        <tr>
                          <td>
                            Date de création
                          </td>
                          <td>{{ shipment.created_datetime | date }}</td>
                        </tr>
                        <tr>
                          <td>
                            Date de livraison
                          </td>
                          <td>
                            {{ shipment.delivered_datetime | date }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Poids (g)
                          </td>
                          <td>{{ shipment.weight }}</td>
                        </tr>
                        <tr>
                          <td>
                            Note
                          </td>
                          <td>
                            <Notes
                              v-if="shipment.id"
                              :id="shipment.id"
                              :type="'Shipment'"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="12"
                  >
                    <v-list
                      dense
                      style="text-align: right"
                    >
                      <v-list-item
                        v-if="capacities.lost"
                      >
                        <v-list-item-title>
                          <v-btn
                            text
                            small
                            color="blue lighten-1"
                            @click="markAsLost()"
                          >
                            Déclarer comme perdu
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="capacities.createParcel"
                      >
                        <v-list-item-title>
                          <v-btn
                            text
                            small
                            color="blue lighten-1"
                            @click="createParcel()"
                          >
                            Créer le colis
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="capacities.createLabel"
                      >
                        <v-list-item-title>
                          <v-btn
                            text
                            small
                            color="blue lighten-1"
                            @click="createLabel()"
                          >
                            Créer le bordereau
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="capacities.downloadLabel"
                      >
                        <v-list-item-title>
                          <v-btn
                            target="_blank"
                            :href="shipment.label_url"
                            text
                            small
                            color="blue lighten-1"
                          >
                            Télecharger le bordereau
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>
                          <v-btn
                            target="_blank"
                            :href="getProformaInvoiceUrl(shipment)"
                            text
                            small
                            color="blue lighten-1"
                          >
                            Télecharger la facture proforma
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-card>
              <v-card-title>
                Articles
                <v-spacer />
                <v-btn
                  :disabled="mode.reorganize"
                  text
                  small
                  color="blue lighten-1"
                  @click="mode.reorganize = true"
                >
                  Réorganiser
                  <v-icon
                    small
                    right
                    color="blue lighten-1"
                  >
                    mdi-vector-polyline-edit
                  </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-data-iterator
                  v-model="selected"
                  :items="shipment.order_items"
                  item-key="id"
                  hide-default-footer
                >
                  <template v-slot:default="props">
                    <v-list
                      v-for="item in props.items"
                      :key="item.id"
                    >
                      <v-list-item
                        three-line
                      >
                        <v-list-item-action
                          class="align-center"
                        >
                          <v-checkbox
                            v-if="mode.reorganize"
                            :input-value="props.isSelected(item)"
                            @click="props.select(item, !props.isSelected(item))"
                          />
                        </v-list-item-action>
                        <v-list-item-avatar
                          tile
                          size="90"
                          class="mr-3"
                        >
                          <v-img :src="item.product.photo_url" />
                        </v-list-item-avatar>
                        
                        <v-list-item-content
                          class="pa-2"
                        >
                          <v-list-item-title
                            class="font-weight-bold pb-3"
                          >
                            {{ item.product.name }}
                          </v-list-item-title>
                          <v-col
                            cols="12"
                            md="4"
                            sm="12"
                          >
                            <v-row class="lh_product__detail py-2">
                              <span class="pr-2">Boutique : </span><span>{{ item.store.name }}</span>
                            </v-row>
                            <v-row class="lh_product__detail py-2">
                              <span class="pr-2">Tailles : </span><span>{{ item.product.sizes }}</span>
                            </v-row>
                            <v-row class="lh_product__detail py-2">
                              <span class="pr-2">Poids (g) : </span><span>{{ item.product.weight }}</span>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            sm="12"
                          >
                            <v-row class="lh_product__detail py-2">
                              <span class="pr-2">Sku : </span><span>{{ item.product.sku }}</span>
                            </v-row>
                            <v-row class="lh_product__detail py-2">
                              <span class="pr-2">EAN : </span><span>{{ item.product.ean_code }}</span>
                            </v-row>
                            <v-row class="lh_product__detail py-2">
                              <span class="pr-2">Référence Externe : </span>
                              <span>{{ item.product.external_reference }}</span>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            sm="12"
                          >
                            <slot />
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                  <template v-slot:no-data>
                    <v-row
                      class="fill-height"
                      align-content="center"
                      justify="center"
                    >
                      <v-col
                        class="subtitle-1 text-center"
                        cols="12"
                      >
                        Aucun article
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="mode.reorganize"
                  :disabled="selected.length < 1"
                  text
                  small
                  color="blue lighten-1"
                  @click="modal.moveToNewOne.opened = true"
                >
                  Nouveau colis
                  <v-icon
                    right
                    small
                  >
                    mdi-package-variant-plus
                  </v-icon>
                </v-btn>
                
                <v-btn
                  v-if="mode.reorganize"
                  :disabled="selected.length < 1"
                  text
                  small
                  color="blue lighten-1"
                  @click="modal.moveToExistingOne.opened = true"
                >
                  Colis existant
                  <v-icon
                    right
                    small
                  >
                    mdi-package-variant-closed-check
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ProviderModal
      :opened.sync="modal.provider.opened"
      :shipment-id="shipment.id"
      :provider.sync="shipment.provider"
      :provider-reference.sync="shipment.provider_reference"
      :carrier.sync="shipment.carrier"
      :tracking-number.sync="shipment.tracking_number"
      @shipmentUpdated="onShipmentUpdated"
    />
    <DeliverModal
      :opened.sync="modal.deliver.opened"
      :shipment-id="shipment.id"
      @shipmentUpdated="onShipmentUpdated"
    />
    <MoveToNewOneModal
      :opened.sync="modal.moveToNewOne.opened"
      :shipment-src="shipment"
      :items="selected"
      @shipmentUpdated="onShipmentUpdated"
    />
    <MoveToExistingOneModal
      :opened.sync="modal.moveToExistingOne.opened"
      :shipment-src="shipment"
      :items="selected"
      @shipmentUpdated="onShipmentUpdated"
    />
    <ConfirmModal ref="confirm" />
    <Snackbar />
  </v-container>
</template>

<script>
import Address from '@/views/Shipments/Edit/Address.vue'
import CarrierChip from '@/components/base/Shipment/CarriererChip.vue'
import DeliverModal from '@/views/Shipments/Edit/DeliverModal.vue'
import EventBus from '@/plugins/event-bus'
import MoveToExistingOneModal from '@/views/Shipments/Edit/MoveToExistingOneModal.vue'
import MoveToNewOneModal from '@/views/Shipments/Edit/MoveToNewOneModal.vue'
import Notes from '@/components/core/Notes.vue'
import ProviderChip from '@/components/base/Shipment/ProviderChip.vue'
import ProviderModal from '@/views/Shipments/Edit/ProviderModal.vue'
import shipment from '@/mixins/shipment'
import Snackbar from '@/components/core/Snackbar.vue'
import StatusChip from '@/components/base/Shipment/StatusChip.vue'
import TrackingNumberLink from '@/components/base/Shipment/TrackingNumberLink.vue'

export default {
  name: 'ShipmentEdit',
  components: {
    TrackingNumberLink,
    MoveToNewOneModal,
    MoveToExistingOneModal,
    Notes,
    Snackbar,
    DeliverModal,
    ProviderModal,
    CarrierChip,
    ProviderChip,
    Address,
    StatusChip,
  },
  mixins: [ shipment ],
  data() {
    return {
      shipmentId: this.$route.params.id,
      capacities: {
        updateSender: false,
        updateRecipient: false,
        
        createParcel: false,
        createLabel: false,
        downloadLabel: false,
        
        refreshStatus: true,
        updateDelivery: false,
      },
      modal: {
        deliver: {
          opened: false,
        },
        provider: {
          opened: false,
        },
        moveToNewOne: {
          opened: false,
        },
        moveToExistingOne: {
          opened: false,
        },
      },
      message: {
        type: null,
        text: null,
        link: null,
      },
      mode: {
        reorganize: false,
      },
      mounted: false,
      request: null,
      loading: false,
      shipment: {},
      selected: [],
    }
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    onShipmentUpdated(message) {
      if (message !== undefined && message.text !== null) {
        EventBus.$emit('SNACKBAR', { color: message.type, message: message.text, link: message.link })
      }
      this.load(this.shipmentId)
    },
    cancel() {
      this.message = { type: null, text: null }
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load(id) {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.loading = true
      this.$axios.get('v3/shipments/' + id, { cancelToken: axiosSource.token })
        .then((response) => {
          this.shipment = response.data
          this.updateCapacities()
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message })
        })
        .finally(() => {
          this.request = null
          this.loading = false
          this.mounted = true
          this.selected = []
          this.mode.reorganize = false
        })
    },
    async markAsLost() {

      this.loading = true

      if (await this.$refs.confirm.open(
        'Colis perdu',
        'Vous allez déclarer ce colis comme perdu. Êtes-vous sûr ?')
      ) {

        this.cancel()
        let axiosSource = this.$axios.CancelToken.source()
        this.request = { cancel: axiosSource.cancel }

        this.loading = true
        this.$axios.post('v3/shipments/' + this.shipmentId + '/lost', {})
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
            let error_message = error.response.data['hydra:description'] ??
              error.response.data['detail'] ??
              'No context'
            this.message.type = 'error'
            this.message.text = this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message })
          }).finally(() => {
            this.request = null
            this.onShipmentUpdated(this.message)
          })
      } else {
        this.loading = false
      }
    },
    createParcel() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.loading = true
      this.$axios.post('v3/shipments/' + this.shipmentId + '/parcels', {})
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message })
        }).finally(() => {
          this.request = null
          this.onShipmentUpdated(this.message)
        })
    },
    createLabel() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.loading = true
      this.$axios.post('v3/shipments/' + this.shipmentId + '/labels', {})
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message })
        }).finally(() => {
          this.request = null
          this.onShipmentUpdated(this.message)
        })
    },
    refresh() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.loading = true
      this.$axios.post('v3/shipments/' + this.shipmentId + '/refresh', {})
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message })
        }).finally(() => {
          this.request = null
          this.onShipmentUpdated(this.message)
        })
    },
    updateCapacities() {
      this.capacities = {
        updateSender: !['EXTERNAL', 'WING_B2B'].includes(this.shipment.provider)
          && ['AWAITING_LABEL_GENERATION'].includes(this.shipment.status),
        updateRecipient: this.shipment.provider_reference === null
          && ['AWAITING_LABEL_GENERATION'].includes(this.shipment.status),
        
        createParcel: this.shipment.provider_reference === null
          && this.shipment.provider !== 'EXTERNAL'
          && ['AWAITING_LABEL_GENERATION'].includes(this.shipment.status),
        createLabel: this.shipment.provider_reference !== null
          && this.shipment.status === 'AWAITING_LABEL_GENERATION'
          && !['EXTERNAL', 'WING_B2B'].includes(this.shipment.provider),
        downloadLabel: this.shipment.label_url !== null,
        
        refreshStatus: this.shipment.status !== 'DELIVERED'
          && this.shipment.deleted_at === null,
        updateDelivery: this.shipment.status !== 'DELIVERED'
          && this.shipment.deleted_at === null,
        lost: !this.shipment.lost,
      }
    },
  },
}
</script>

<style scoped>
.lh_shipment__details tr td:first-child {
  font-weight: bolder;
}
</style>
